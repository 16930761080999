import React, { useState } from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { IconContext } from "@react-icons/all-files";
import { FaShareAlt } from "@react-icons/all-files/fa/FaShareAlt";
import { FaTrophy } from "@react-icons/all-files/fa/FaTrophy";

import Timeline from "../components/experience/timeline";
import Certifications from "../components/experience/certifications";

import styles from "../styles/buttonGroup.module.scss";

export default function Experience() {
  const [ tab, setTab ] = useState(0); // have a mapping of tabs
  return (
    <div>
      <div className={ `header__container` }>
        <h1 className={ `header` }>Experience</h1>
        
        <ButtonGroup className={ styles.button__group } size="small" disableElevation color="primary">
          <Button variant={ tab === 0 ? 'contained' : 'outlined' } onClick={ () => setTab(0) }>
            <IconContext.Provider value={{ size: "13px" }}>
              <FaShareAlt className={ `icon__left icon__1px__down ${ styles.button__group__icon }`} />
            </IconContext.Provider>
            <span>Timeline</span>
          </Button>
          <Button className={ styles.button__anchor } variant={ tab === 1 ? 'contained' : 'outlined' } onClick={ () => setTab(1) }>
            <IconContext.Provider value={{ size: "15px" }}>
              <FaTrophy className={ `icon__left icon__1px__down ${ styles.button__group__icon }`} />
            </IconContext.Provider>
            <span>Certifications &amp; Awards</span>
          </Button>
        </ButtonGroup>
      </div>

      { tab === 0 ? <Timeline/> : <Certifications/> }
      
    </div>
  );
};
