import React from "react";
import styles from '../styles/card.module.scss';

export default function Card({ children, className }) {
  return (
    <div className={ `${ styles.card } ${ className }` }>
      { children }
    </div>
  );
};
