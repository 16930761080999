import React from "react";
import { Link } from "gatsby";
import Card from '../card';

import styles from '../../styles/certifications.module.scss';
import cardStyles from '../../styles/card.module.scss';

export default function Certifications() {
  return (
    <div className={ styles.certifications }>
      {/* DECEMBER 2021 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          December 2021
        </div>
        <div className={ cardStyles.card__header }>
          <b>Apollo Graph Developer &mdash; Associate</b>
        </div>
        <div className={ cardStyles.card__text}>
          Completed curriculum for Apollo GraphQL's first offered certification.
        </div>
        <div className={ cardStyles.card__text}>
            <a 
            className="link" href="https://odyssey.apollographql.com/certifications/f859e194-69ce-481e-8a0a-5bff6dd5a4a9" rel="noopener noreferrer" target="_blank">
            View Certificate
            </a>
        </div>
      </Card>

      {/* SEPTEMBER 2018 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          September 2018
        </div>
        <div className={ cardStyles.card__header }>
          <b>Amazon Web Services Solutions Architect &mdash; Associate</b>
        </div>
        <div className={ cardStyles.card__text}>
          Score: <b>920 / 1000</b>
        </div>
        <div className={ cardStyles.card__text}>
          Credential ID: XD54X492KJ4Q1QWH | <a 
            className="link" href="https://aw.certmetrics.com/amazon/public/verification.aspx" rel="noopener noreferrer" target="_blank">
            View Certificate (use Credential ID)
            </a>
        </div>
      </Card>

      {/* JUNE 2017 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          June 2017
        </div>
        <div className={ cardStyles.card__header }>
          <b>Bachelor of Arts in Economics &amp; <br/>Bachelor of Science in Computer Science &mdash; <br/>The University of Chicago</b>
        </div>
      </Card>

      {/* 2013 - 2017 */}
       <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          2013 &mdash; 2017
        </div>
        <div className={ cardStyles.card__header }>
          <b>Dean’s List &mdash; The University of Chicago</b>
        </div>
        <div className={ cardStyles.card__text}>
          Maintained above a 3.25 GPA every quarter while pursuing a double major.
        </div>
      </Card>

      {/* February 2016 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          February 2016
        </div>
        <div className={ cardStyles.card__header }>
          <b>Semi-finals &mdash; Microsoft Imagine Cup</b>
        </div>
        <div className={ cardStyles.card__text}>
          Part of a 7-member team that built <Link className="link" to="/portfolio/we-eat" >
            WeEat
          </Link>, a food delivery app that would help UChicago students save money via bulk ordering.
        </div>
      </Card>

      {/* March 2015 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          March 2015
        </div>
        <div className={ cardStyles.card__header }>
          <b>Top Ten &amp; Best use of Google API &mdash; SpartaHack</b>
        </div>
        <div className={ cardStyles.card__text}>
          Won these 2 awards with 3 other teammates for work on <a 
            className="link"
            href="https://devpost.com/software/volume"
            rel="noopener noreferrer"
            target="_blank">Volume</a>, an annotated reader website aimed for use by humanities professors and students.
        </div>
      </Card>

      {/* 2013 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          2013
        </div>
        <div className={ cardStyles.card__header }>
          <b>National Merit Scholarship</b>
        </div>
        <div className={ cardStyles.card__text}>
          Became a National Merit Finalist and received an annual scholarship fund for my time at The University of Chicago. 
        </div>
      </Card>

      {/* 2013 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          2013
        </div>
        <div className={ cardStyles.card__header }>
          <b>Member of Cum Laude Society &mdash; Polytechnic School</b>
        </div>
        <div className={ cardStyles.card__text}>
          Granted membership into the Cum Laude Society upon high school graduation.
        </div>
      </Card>

      {/* 2012 - 2013 */}
      <Card className={ styles.card }>
        <div className={ cardStyles.card__label }>
          2012 &mdash; 2013
        </div>
        <div className={ cardStyles.card__header }>
          <b>Various National High School Awards</b>
        </div>
        <div className={ cardStyles.card__text}>
          Awarded CollegeBoard’s <b>AP Scholar with Distinction</b> 2 years in a row. <br/> 
          Also received the <b>National Spanish Silver Award</b>.
        </div>
      </Card>

    </div>
  )
};