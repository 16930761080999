import React from "react";
import { IconContext } from "@react-icons/all-files";
import { FaLongArrowAltRight } from "@react-icons/all-files/fa/FaLongArrowAltRight";
import styles from "../../styles/timeline.module.scss";

export default function Timeline() {
  return (
    <div className={ styles.timeline }>

      {/* LEFT -- LINE */}
      <div className={ styles.timeline__left }>
        <div className={ styles.line }></div>
      </div>

      {/* RIGHT -- CONTENT */}
      <div className={ styles.timeline__right }>
        {/* 0 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>March 2022 &mdash; Present </div>
            <div className={ styles.role }>Engineer &mdash; Slalom</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  Near the end of 2021, I decided I wanted to transition from working on my own business to going back to the tech industry. I missed mentorship and wanted to work towards my goal of becoming a software architect. In order to reach that goal, I'd need more exposure to a number of different technical problems and solutions.
                </p>
                <p>
                  I ended up joining a new, talented internal engineering team at <a
                  href="https://www.slalom.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link">
                  Slalom</a> focused on building reusuable cloud assets and internal tools for Slalom consultants. Following in the steps of my start-up days, I've found myself wearing multiple hats and jumping between 
                  <ul>
                    <li>Working closely with AWS for cost-optimized infrastructure solutions</li>
                    <li>Diving into Node.js backend code, building serverless APIs from the ground up, and following test-driven-development with Jasmine</li>
                    <li>Building off of Slalom's branding to create a lightweight design system in Figma + then producing detailed UI mock-ups with Figma prototyping</li>
                    <li>Deepening my React.js skillset and thinking through different state management scenarios</li>
                    <li>Continuously learning technologies (TypeScript, DynamoDB, Terraform, etc.)</li>
                  </ul>
                </p>
                <p>
                  Outside of tackling interesting technical, product, and design challenges, my favorite part of Slalom has been the people. I'm immensely grateful for how much mentorship I have received from our team's director, architect, and senior engineer. It's also been amazing to pay that mentorship forward and have the opportunity to onboard and teach our team's Cloud Residents (newer engineers who have 0-2 years of industry experience).
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* 1 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>November 2018 &mdash; Present </div>
            <div className={ styles.role }>Co-Founder, Full-Stack Developer &amp; Designer &mdash; ProfitKit</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  Starting my own small business has been something I've dreamt of since high school. After gaining some technical experience and business insights through our full-time work and freelance projects, my partner Matt and I decided to build a SaaS, software-as-a-service, product. 
                </p>
                <p>
                  We chose to build <a className="link" href="https://profitkit.io/" target="_blank" rel="noopener noreferrer">ProfitKit</a>, a tool that projects daily cash flows, breaks down revenue analytics, automates customer outreach, and recovers failed payments for subscription businesses that use the payment processor Stripe. Matt and I have bootstrapped (no external funding) this business entirely and have handled everything — user research, design, development, software architecture (scaling up the app), product management, customer support, and marketing.
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/profitkit">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* 2 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>July 2020, November 2020 &mdash; March 2021</div>
            <div className={ styles.role }>Technical Consultant &amp; Full-Stack Developer &mdash; Stealth Product (NDA)</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  A small community of entrepreneurs knew about my work through my tech tutorials blog, <a className="link" href="https://codenebula.io/blog/" target="_blank" rel="noopener noreferrer">CodeNebula</a>. One entrepreneur reached out to me on CodeNebula and asked if I'd be willing to help them integrate the payment processor Stripe into their marketplace application. 
                </p>
                <p>
                  After doing some initial consulting work and enjoying it, I ended up taking on the project as a part-time remote opportunity on top of ProfitKit. The core of the work involved architecting and documenting onboarding, cart, checkout, and Stripe Payment Intent state workflows with Stripe Express.
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/stealth-product-nda">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* 3 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>February 2018 &mdash; November 2018 </div>
            <div className={ styles.role }>Full-Stack Developer &amp; Designer &mdash; Electrum Performance</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  To get more hands-on experience with web development, I decided to freelance after work and on the weekends. With another developer, I built out the initial versions of <a className="link" href="https://electrumperformance.com/" target="_blank" rel="noopener noreferrer">Electrum Performance</a>, an online subscription-based training program containing 96 workout templates, a library of 50+ videos, and 25 levels for athletes to track exercises and strength goals. 
                </p>
                <p>
                  We worked closely with the two personal trainers who devised the training program and translated their requirements into code. Interestingly enough, while working on this product, I picked up additional skills outside of web development — product management and UI/UX design — which would help me start my own business.
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/electrum-performance">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* 4 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>July 2017 &mdash; November 2018 </div>
            <div className={ styles.role }>Associate Software Engineer &mdash; Morningstar</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  After graduating from UChicago, I immediately began working for Morningstar, an independent investment research firm based in Chicago. I joined the Notes, Notifications, and Alerts (a.k.a., Nerts) team on Morningstar Direct Cloud, a new web-based version of the legacy desktop application that helps advisory firms manage clients.
                </p>
                <p>
                  I worked in an agile, cross-team environment (Shenzhen + Chicago) to develop features bi-weekly. Much of the feature work consisted of building complex UI components that would be injected into various parts of the overall application. Morningstar helped me develop an interest in software architecture and hone my full-stack development skills.
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/morningstar">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* 5 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>March 2017 &mdash; January 2018</div>
            <div className={ styles.role }>Front-End Developer &amp; Assistant &mdash; Support &amp; Resist</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  Towards the end of my last year of college, I wanted to put my degrees to work and check out the fintech space. Interestingly enough, a friend of mine was transitioning his freelance work into a productized service that would automate and test the strategies of retail traders against years of historical data, and he asked me if I could help him out.
                </p>
                <p>
                  While I did help code up a few algorithms for clients, I actually ended up helping my friend make several business decisions — such as the name of the business and managing + bringing more people on board. I also designed and coded up many, many iterations of the marketing site (from a simple HTML page hosted on Github Pages &#8594; a Squarespace site &#8594; a custom-coded Wordpress site that led me to use AWS EC2 for the first time). During this process, I realized that my passion lied more in the creativity of web development and less in trading algorithms!
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/support-and-resist">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* 6 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>June 2016 &mdash; September 2016 </div>
            <div className={ styles.role }>Research Assistant &mdash; The University of Chicago</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  During my third year of college, I took a course called Functional Programming in Elm. While working on my final project (a web-based version of Adobe Illustrator's Pen Tool) for this class, I asked my professor a few questions and he pointed me towards a project he was working on for inspiration — Sketch-n-Sketch, a "prodirect" (programming + direct manipulation) SVG editor.
                </p>
                <p>
                  I found Sketch-n-Sketch to have many applications (e.g., teaching artists to code + helping programmers build customizable and reusable graphics/animations) and asked to help contribute to it. I worked on a series of data visualization templates — vertical bar charts (regular, clustered, and stacked), pie charts (regular and donut), and pictograms — that would allow users to start with a default option and customize it either through the code (programmatic side) or through the editor (direct manipulation).
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/sketch-n-sketch">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* 7 */}
         <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>August 2015 &mdash; October 2015 </div>
            <div className={ styles.role }>Marketing Assistant &mdash; The Synergy Events</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  In college, I often found myself wondering what it would be like to work in digital marketing. Following that curiosity led me to discover a temporary remote position as a marketing assistant for The Synergy Events, an event planning company. 
                </p>

                <p>During my time there, I</p>
                  
                <ul>
                  <li>
                    Helped test and edit both Pulse 24/7 Manager, a new mobile booking app, and the corresponding website, <a className="link" href="http://pulse247.net" target="_blank" rel="noopener noreferrer">pulse247.net</a>
                  </li>
                  <li>
                    Often spoke directly with the CEO to strategically devise campaign and crowdfunding strategies for the app and recruit sales and marketing interns
                  </li>
                  <li>
                    Sent administrative updates to photographers and volunteers, asked salons to partner with us, and promoted designers via Instagram for The Emerging Trends, our 8th annual Boston Fashion Week show
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* 8 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>September 2013 &mdash; June 2017 </div>
            <div className={ styles.role }>Undergraduate Student &mdash; The University of Chicago</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  I attended The University of Chicago (UChicago) for undergraduate studies and received bachelor degrees in Economics and Computer Science. I initially intended on studying just Economics but quickly fell in love with Computer Science after taking a few courses and realizing how many things you could build/do if you knew how to code.
                </p>
                <p>
                  <i>"That’s all well and good in practice...but how does it work in theory?"</i> is a common joke at UChicago, since so many classes focus on theory and academia. While I did enjoy many of the theory-heavy classes, my favorites were ones that combined theory and application — Data Visualization, Introduction to Databases, Mobile Computing, Software Construction, Functional Programming, Programming Languages, Econometrics, and Introduction to Finance.
                </p>
              </div>
            </div>
          </div>
        </div>


        {/* 9 */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ styles.date }>November 2012 &mdash; May 2014 </div>
            <div className={ styles.role }>Fashion &amp; Graphic Designer &mdash; Knorts Clothing Line</div>
            <div className={ styles.description }>
              <div className={styles.description__body}>
                <p>
                  My first entrepreneurial experience came from working on a new clothing line called <a className="link" href="https://knorts.com/" target="_blank" rel="noopener noreferrer">
                  Knorts</a> (short for "knitted shorts"). I collaborated with my employer via Skype, and together, we brainstormed innovative knitted shorts designs.
                </p>
                <p>
                  The work I did primarily involved developing detailed sketches of prototype knorts, which served as blueprints for the manufacturer. Eventually, I branched out into doing some graphic design work with Adobe Illustrator and Photoshop to create various logo ideas that were featured on the website, Facebook page, and May 2014 Kickstarter.
                </p>
              </div>
              <div className={styles.description__footer}>
                <a className={styles.link} target="_blank" href="/portfolio/knorts">
                  Learn More
                  <IconContext.Provider value={{ size: "20px" }}>
                    <FaLongArrowAltRight className={ `icon__right` } />
                  </IconContext.Provider>
                </a>
              </div>
            </div>
          </div>
        </div>


        {/* END */}
        <div className={ styles.timeline__container }>
          <div className={ styles.dot } />
          <div className={ styles.content }>
            <div className={ `${styles.date} ${styles.date__end}` }>The Beginning</div>
          </div>
        </div>
      </div>
    </div>
  )
};
